<template>
  <div>
    <div class="title">
      <van-icon name="arrow-left" class="lefticon" @click="goBack()" />
      <p>系统介绍</p>
    </div>
    <div class="container">
      <div
        class="main"
      >学信检测是专门为大学生提供论文检测服务的平台，我们与维普、万方、知网、联文等知名论文检测系统建立了深厚的合作关系，直接对接官方检测系统，减少论文传输过程存在的泄露风险，为大家提供安全可靠的论文检测服务。</div>
      <div class="main" style="font-weight: bold;">所有报告均为正版，支持验证</div>

      <div class="main">学信检测是官方授权检测平台，所有报告均出自官方，与学校检测结果一致。</div>

      <div class="main" style="font-weight: bold;">检测不留痕迹，不收录论文</div>

      <div class="main">系统有严格的论文防泄漏措施(仅做检测分析，检测完成后自动删除原文)，保证论文安全。</div>
      <div class="main" style="font-weight: bold;">支持手机查看检测结果</div>

      <div class="main">率先接入微信公众号，检测完成实时通知查重结果，随时随地查看检测报告，无需一直守在电脑前。</div>
      <div class="main" style="font-weight: bold;">完善的售后服务系统</div>

      <div class="main">我们有专门的服务专线：400-823-8869，人工客服微信：cx5078</div>
      <div class="main">随时帮您解决检测过程中遇到的问题。</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.title {
  display: flex;
  background: #228ef7;
}
.lefticon {
  line-height: 0.8rem;
  padding: 0 0.3rem;
  color: #fff;
  font-size: 0.3rem;
}
.title p {
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
  flex: 0.85;
}
.container {
  padding: 0.3rem;
  background-color: #fff;
}
.main {
  font-size: 0.3rem;
  text-indent: 0.6rem;
  margin-bottom: 0.2rem;
  line-height: 0.7rem;
}
.mains {
  height: 5rem;
}
</style>